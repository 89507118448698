body {
  background-color: #131211;
  min-height: 100vh;
}

.center-content {
  justify-content: center;
  align-items: center;
}

.welcome-page {
  text-align: center;
  background-color: #131211;
  background: url("../src/Assets/Images/bg-image-for-signup.png") no-repeat
    center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.onboarding-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.music-container {
  min-height: 100vh;
  margin-right: 218px;
  margin-left: 208px;
}

.profiles-page {
  background-color: #131211;
}

.welcome-box {
  width: 480px;
  height: 517px;
  background: #131211;
  border-radius: 16px;
  box-shadow: 10px 20px 50px rgba(19, 18, 17, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ffb84a;
}

h1 {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 140%;
}

.account-create-box {
  display: flex;
  align-self: center;
  gap: 8px;
  margin-bottom: 54px;
}

.grey-text {
  color: #b5b2b0 !important;
}

.gold-text {
  color: #ffb84a;
}

.invalid-text {
  color: #f54141;
}

.hidePassword {
  background-color: #272625;
  margin-right: 17.87px;
  align-self: center;
  display: flex;
}

.loginTitle {
  font-weight: 700;
  font-size: 30px;
  line-height: 140%;
  color: #ffffff;
  text-align: center;
  margin-top: 68px;
}

.password-helper-text {
  margin-right: 36px;
  align-self: flex-end;
}

img.imageAvatar {
  border-radius: 100%;
}

.imageAvatar.showHover:hover {
  outline: 4px solid #ffb84a;
  border-radius: 100%;
}

.logo-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-text-container > h1 {
  margin-bottom: 56px;
  margin-top: 16px;
}

.imageContainer > p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #ffffff;
}

.avatar-container {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

.input-container {
  display: flex;
  gap: 20px;
}

.pin-input {
  height: 80px;
  width: 80px;
  background: #272625;
  border-radius: 16px;
  border-color: transparent;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #ffffff;
  margin-bottom: 4px;
}

.pin-input:focus {
  outline: 1px solid #4f4c4a;
}

.pin-input[data-valid="false"] {
  outline: 1px solid #f54141;
}

.pin-header {
  margin-top: 16px;
}

.pin-subheader {
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 40px;
}

.incorrect-pin {
  margin-top: 4px;
  margin-bottom: 5px;
}

.login-header {
  display: flex;
  position: absolute;
  z-index: 10;
  width: 100%;
  padding-left: 45px;
  margin-top: 24px;
}

.login-header.profiles {
  box-shadow: inset 0px -1px 0px #4f4c4a;
  height: 50px;
}

/* .login-header.home {
  box-shadow: inset 0px -1px 0px #4f4c4a;
  height: 80px;
  margin-bottom: 20px;
  position: static;
  margin-top: 0px;
  justify-content: space-between;
  align-items: center;
}

.login-header.content {
  box-shadow: inset 0px -1px 0px #4f4c4a;
  height: 80px;
  margin-bottom: 20px;
  position: static;
  margin-top: 0px;
  justify-content: space-between;
  align-items: center;
} */

.login-header.full-header {
  box-shadow: inset 0px -1px 0px #4f4c4a;
  height: 80px;
  margin-bottom: 20px;
  position: static;
  margin-top: 0px;
  justify-content: space-between;
  align-items: center;
}

.login-header.playlist,
.album,
.artist {
  box-shadow: none !important;
}

.content-container {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  margin-left: 24px;
  margin-right: 24px;
  justify-content: center;
  gap: 24px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.content-container::-webkit-scrollbar {
  display: none;
}

.similar-artists {
  height: auto !important;
}

.child-content {
  width: 224px;
  max-width: 100%;
  margin-bottom: 40px;
}

.title-container {
  display: flex;
  margin-top: 42px;
  margin-bottom: 32px;
  justify-content: center;
}

.content-screen {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.pointer {
  cursor: pointer;
}
