.flex {
  display: flex;
}

.content-line {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 2px;
  padding-left: 10px;
  border-radius: 8px;
  margin-left: 24px;
  margin-right: 24px;
}

.content-offset {
  margin-left: 14px !important;
}

.album-content {
  margin-top: 40px;
}

.content-text {
  flex-direction: column;
}

.content-details {
  align-items: center;
}

.content-image {
  height: 64px;
  width: 64px;
  border-radius: 8px;
}

p.content-info {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  margin: 0;
}

.content-image {
  margin-right: 16px;
  z-index: 100;
}

.title {
  margin-bottom: 4px !important;
}

.grey-text {
  color: #b5b2b0 !important;
}

.grey-hover:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.content-line:active:not(:has(:active)) {
  background-color: rgba(255, 255, 255, 0.1);
}

.more-icon {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.more-icon:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.more-icon:active {
  background-color: rgba(255, 255, 255, 0.1);
}

.content-line:hover * .content-image {
  background: rgba(0, 0, 0, 0.4);
  opacity: 40%;
  z-index: 1;
}

.header-container {
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.header-container * p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #b5b2b0;
}

.header-image {
  height: 224px;
  width: 224px;
  margin-right: 20px;
  margin-left: 20px;
}

.artist-image {
  border-radius: 100%;
}

.header-dot {
  height: 4px;
  width: 4px;
  border-radius: 100%;
  background-color: #b5b2b0;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.header-info {
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 24px;
}

.header-info > div {
  align-items: center;
}

.center {
  align-items: center;
  justify-content: center;
}

.info-name {
  margin-bottom: 0px;
}

.artist-name {
  margin-bottom: 32px;
}

.metadata > p {
  margin-top: 8px;
}

.button-container {
  margin-top: 8px;
}

.more-header {
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  background-color: #272625;
}

.more-header:hover {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    #272625;
}

.more-header:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #272625;
}

.albumdetails-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.song-title {
  margin-left: 18px;
}

.white-text {
  color: #ffffff;
}

.playicon-container {
  width: 25px;
  align-items: center;
  justify-content: center;
}

.more-by-list {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 86px;
}

.artist-additional {
  margin-bottom: 76px;
  margin-right: 24px;
  margin-left: 10px;
}

.top-songs {
  align-items: center;
  justify-content: space-between;
  margin-left: 24px;
  margin-right: 24px;
}

.latest-container {
  justify-content: center;
  align-items: center;
}

.latest-item {
  height: 190px;
  width: 720px;
  background-color: #272625;
  align-items: center;
  border-radius: 16px;
}

.latest-item:active:not(:has(:active)) {
  background-color: rgba(255, 255, 255, 0.1);
}

.latest-title {
  margin: 0;
}

.similar-artist {
  justify-content: center;
}

.subheader {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #ffffff;
}

.subheader-name {
  margin-top: 10px;
  margin-bottom: 4px;
  margin-right: 10px;
}

.subheader-description {
  margin-top: 0;
  margin-bottom: 4px;
}

.bio-header {
  align-items: center;
  justify-content: space-between;
  width: 720px;
  margin-bottom: 10px;
}

.bio-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
  justify-content: center;
  align-items: center;
}

.bio-card {
  background-color: #272625;
  border-radius: 18px;
}

.bio-photo {
  width: 723px;
  height: 576px;
  display: flex;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.artist-name {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #ffffff;
  margin-top: 0px;
  margin-bottom: 16px;
}

.artist-bio {
  width: 640px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5; /* start showing ellipsis when 5th line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
  margin-top: 0px;
  margin-bottom: 0px;
}

.bio-info {
  padding-left: 20px;
  padding-right: 20px;
}

.bio-info:active {
  background-color: rgba(255, 255, 255, 0.1);
}

.bio-subheader-container {
  flex-direction: column;
  margin-right: 24.5px;
  margin-bottom: 50px;
  margin-top: 50px;
}

.data-container {
  margin-top: 76px;
  margin-bottom: 56px;
}
