.arrow.left {
  transform: rotate(180deg);
}

.right {
  margin-left: 1px;
}

.left {
  margin-left: -1px;
}
