.primary.button {
  background-color: #ffb84a;
  color: #131211;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  padding: 3% 8%;
  gap: 8px;
  border-radius: 24px;
  width: 80%;
  height: 10%;
  align-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-color: transparent;
  margin-top: 34px;
}

.secondary.button {
  background-color: #272625;
  color: #ffb84a;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  padding: 3% 8%;
  gap: 8px;
  border-radius: 24px;
  align-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-color: transparent;
}

.header-button {
  margin-right: 20px;
  width: 192px !important;
  height: 48px !important;
}

.primary.header-button {
  margin-top: 0px !important;
}

.primary.button:hover {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    #ffb84a;
}

.primary.button:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #ffb84a;
}

.secondary.button:hover {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    #272625;
}

.secondary.button:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #272625;
}

.primary.button:disabled {
  background-color: #4f4c4a;
  color: #b5b2b0;
}

.navigation {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  cursor: "pointer";
}

.navigation:disabled {
  opacity: 50%;
}

.navigation:active:enabled {
  background: rgba(255, 255, 255, 0.3);
}

.arrow-container {
  display: flex;
  margin-left: 10px;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.view-all {
  margin-right: 16px;
  cursor: pointer;
}
