.category-container {
  display: flex;
  gap: 8px;
  width: 87%;
}

.card-container {
  width: 244px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.artist-item {
  border-radius: 100%;
  height: 266px;
  width: 240px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.genre-item {
  border-radius: 16px;
  height: 140px;
  width: 352px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
}

.wrap {
  position: relative;
  cursor: pointer;
}

.wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 98%;
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
  z-index: 999;
}

.genre-image {
  height: 140px;
  width: 352px;
  border-radius: 16px;
}

.genre-text {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
}

.genre-text-container {
  width: 100%;
  position: absolute;
  top: 25%;
}

.artist-image {
  border-radius: 100%;
  height: 224px;
  width: 224px;
}

.card-container.hover:hover {
  background: rgba(255, 255, 255, 0.05);
  /* width: 120%; */
  height: 100%;
}

.card-container:active {
  background: rgba(255, 255, 255, 0.1) !important;
}

.card {
  height: 224px;
  width: 224px;
  border-radius: 16px;
}

.album-item {
  margin-bottom: 16px;
  display: flex;
  align-self: center;
}

.card-container:hover * .album-image {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 40%;
}

.album-details {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.album-text {
  font-size: 16px;
  margin: 0px;
}

.artist-text {
  font-size: 16px;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.shimmer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.round-logo {
  border-radius: 100%;
}

.placeholder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.album-skeleton {
  height: 120%;
}

.new-releases {
  margin-bottom: -20px;
}

.categories-margin {
  margin-bottom: 36px;
}

.offset-text {
  margin-left: 10px;
}

.dot-separator {
  height: 4px;
  width: 4px;
  border-radius: 100%;
  background-color: #b5b2b0;
  margin-top: 8px;
  margin-left: 5px;
  margin-right: 5px;
}
