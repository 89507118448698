.header-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  font-style: normal;
}

.header-icon-container {
  margin-right: 32px;
}

.header-icon-container:last-of-type {
  margin-right: 0;
}

.header {
  display: flex;
}

.category-icon {
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.avatar-container {
  align-items: center;
  justify-content: center;
}

.avatar-name {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #ffffff;
  margin-left: 8px;
  margin-right: 10px;
}

.user-container {
  align-items: center;
  justify-content: center;
  margin-right: 80px;
  margin-left: 40px;
  margin-top: 2px;
}
